<template>
  <div class="container_detail">
    <div class="header_Css">
      <div @click="toBack"><img src="../assets/back.png" alt=""></div>
      <h2>Recompensas Acumuladas de Registro</h2>
      <span @click="toUrl('/home/records?num=12')">Histórico</span>
        <button v-if="flag"  :style="{backgroundColor: 'orange',borderRadius: '10%',width:'20%',height: '100%',marginLeft:'10px'}" @click="check_in()">Registro</button>
        <button v-else :style="{backgroundColor: 'slategray',borderRadius: '10%',width:'20%',height: '100%',marginLeft:'10px'}" @click="">Registro</button>
    </div>
    <div style="height: 10px;"></div>
<!--    <div class="qr-info-box1">-->
<!--      <div style="margin-left: 5px;">Pessoas de nível inferior eficazes <span style="color:#feb705;cursor: pointer;"-->
<!--          @click="show = true">{{ boxList.userCount }}</span> pessoas <span style="color:#feb705;cursor: pointer;"-->
<!--          @click="show = true">Detalhes</span>-->
<!--      </div>-->
      <div>Dias acumulados de login: {{boxList.checkinDays}}    Bônus recebidos: {{boxList.checkInReward}} </div>
<!--    </div>-->

<!--    <div class="part_three">-->
<!--      <p>Qual é o número de jogadores efetivamente promovidos? (cumprir todas as condições indicadas abaixo)</p>-->
<!--      &lt;!&ndash; <div class="part_three_1">-->
<!--                <span>Este subordinado recarrega pela primeira vez</span><span style="color: #fff;">10 Ou o acima-->
<!--                    mencionado</span>-->
<!--            </div> &ndash;&gt;-->
<!--      <div class="part_three_1">-->
<!--        <span>O subordinado acumulou recargas</span><span style="color: #fff;">{{ boxList.rechargeSum }} Ou o acima-->
<!--          mencionado</span>-->
<!--      </div>-->
<!--      <div class="part_three_1" style="background: #000;">-->
<!--        <span>O subordinado acumulou apostas</span><span style="color: #fff;">{{ boxList.pickGamecoin }} Ou o acima-->
<!--          mencionado</span>-->
<!--      </div>-->
<!--      &lt;!&ndash; <div class="part_three_1" style="background: #000;">-->
<!--                <span>O subordinado acumulou o nº de depósitos</span><span style="color: #fff;">1 Vezes ou mais</span>-->
<!--            </div> &ndash;&gt;-->
<!--    </div>-->

    <div class="part_four">
      <block v-if="boxList.id" v-for="(item, index) in 1" :key="index">
        <p v-if="index" class="display: block; width: 100%; height: 25px; !important"></p>
        <div class="part_four_0" :data="item">
          <div class="part_four_1" style="display: flex;">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <div class="d_flex-div" v-for="(item1, index1) in boxList.itemList">
                <div class="part_four_1_1" @click="openBox(item1)">
                    <div v-if="item1.userActivityStatus == 0":style="{color:'#e8e733'}">Aguardando</div>
                    <div v-else-if="item1.userActivityStatus == 1" :style="{color:'#565853'}">Recebido</div>
                    <div v-else :style="{color:'#ffffff'}">Não disponível</div>
                  <div style="border: 1px solid rgb(111 110 110);width: 100%;display: flex;flex-direction: column;align-items: center;background: rgb(25, 25, 25);margin: 5px 0;border-radius: 10px;">
<!--                    <img v-if="item1.status == null" src="../assets/open1.png" alt="">-->
                    <img v-if="item1.sortNum == 0 " src="../assets/10001.png">
                    <img v-else-if="item1.sortNum == 1 " src="../assets/10002.png">
                    <img v-else-if="item1.sortNum == 2 " src="../assets/10003.png">
                    <img v-else-if="item1.sortNum == 3 " src="../assets/10004.png">
                    <img v-else-if="item1.sortNum == 4 " src="../assets/10005.png">
                    <img v-else-if="item1.sortNum == 5 " src="../assets/10006.png">
                    <img v-else-if="item1.sortNum == 6 " src="../assets/10007.png">
                    <img v-else src="../assets/10007.png">
<!--                    <img v-if="item1.userActivityStatus == 0" src="../assets/open2.png" alt="">-->
<!--                    <img v-else-if="item1.userActivityStatus == 1" src="../assets/open3.png" alt="">-->
<!--                    <img v-else src="../assets/open1.png" alt="">-->
                    <div v-if="item1.recommendNum < 10"
                         style="font-size: 15px;width: 100%;color: #ffaa09;font-weight: bold;" align="center">
                        {{ item1.recommendNum }} {{item1.rewardAmount}}
                    </div>
                    <div v-else
                      style="position: relative; font-size: 10px; width: 50px;text-align: center; color: #fff;">
                      <div style="position: absolute; top: -6px;width: 100%; color: #fff; text-align: center;">
                        {{ item1.recommendNum }}
                      </div>
                      <!-- <span v-if="item1.recommendNum > 999">
                        <br />
                      </span> -->
                      <div style="position: absolute; top: 3px; width: 100%; text-align: center; color: #fff;"> pessoas
                      </div>
                    </div>
                  </div>
                  <span :style="item1.recommendNum > 9 ? 'margin-top: 20px;' : 'margin-top: 5px;'">Día {{
                    item1.cumDays
                    }}</span>
                </div>

              </div>
            </div>
            <!-- <div v-if="item ==1 || item == 3" class="toRight"> -->
            <!-- <div style="display: flex;width: 100%;" :style="{'flex-direction':(index+1)% 2 != 0? 'row':'row-reverse'}">
              <div style="flex: 1;"></div>
              <img style="padding: 0 10px;" :style="index % 4 === 0 ? 'margin-right: 10px;' : 'margin-left: 10px;'"
                v-if="item !=totalLine" src="../assets/down.png" alt="">
            </div> -->
            <!-- </div> -->
            <!-- <div v-else-if="item ==2 || item == 4" class="toLeft">
                          <img src="../assets/down.png" alt="">
                      </div> -->
          </div>
        </div>
      </block>
    </div>
      <div class="part_five" style="padding:10px;text-align: justify">
      <span>I.Hora do evento(UTC-3):</span>
      <div>
        <span>
            03/01/2024 00:00:00 - 30/11/2024 23:59:59
        </span>
      </div>
      <span>II.Instruções Do Evento:</span>
      <div>
          1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para
          o registro diário. <br>2.As apostas por registro não estão limitadas a nenhuma plataforma específica.
          A atualização das recompensas está prevista para ocorrer em 10 minutos. Aguarde a distribuição das
          recompensas<br>3.Este evento é uma atividade de entrada contínua. Se for interrompido durante o período,
          o seu progresso reiniciará do 1º dia;<br>4.As recompensas só podem ser resgatadas manualmente no aplicativo
          APP/iOS、APP/Android、H5、PC/Windows. Recompensas não resgatadas serão invalidadas <br>5.Para sacar os bônus
          (excluindo o depósito original) desta atividade, você precisa apostar 3 vezes o valor do bônus (também
          conhecido como rollover, validação ou aposta válida). Você pode apostar em qualquer plataforma de jogos
          <br>6.Este evento é limitado a operações normais realizadas pelo titular da conta. É proibido alugar, usar
          plug-ins externos, robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo,
          exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso contrário,
          as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada à lista negra;
          <br>7.Para evitar diferenças na compreensão do texto, a plataforma reserva-se o direito de interpretação
          final deste evento.
      </div>
    </div>
<!--    <div class="qqq">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">-->
<!--    </div>-->
    <van-popup v-model="show">
      <div class="popupCSS">
        <h2>Link de Convite</h2>
        <div class="p1">
          <!-- <van-dropdown-menu>
                        <van-dropdown-item v-model="value1" :options="option1" />
                    </van-dropdown-menu> -->
          <div class="p1_1">Válido ou não <img src="../assets/singldown.png" alt=""></div>
          <div class="p1_2"><input type="text" style="width: 100px;background: #000;border:none" placeholder="Conta">
            <img src="../assets/search.png" alt="">
          </div>
        </div>
        <div class="nothing_css">
          <img style="width: 110px;" src="../assets/nothing.png" alt="">
          <div>Sem Registros</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
    activityDetail,
    receiveAccumulatedCheckinReward,
    checkin,
    getCheckinStatus,
} from '@/api/index'
import DropdownMenu from "@/components/DropdownMenu.vue";
import VueQr from "vue-qr";

export default {
  components: {
    DropdownMenu,
    VueQr,
  },
    data() {
        return {
            show: false,
            timerId:null,
            flag: true,
            value1: 0,
            value2: 'a',
            option1: [
                { text: 'Válido ou não', value: 0 },
                { text: 'Eficiente', value: 1 },
                { text: 'Inválido', value: 2 }
            ],
            boxList: {
            },
            totalLine: 0,
          active: 0,
          domain: '',
          option: [
            {
              label: '',
              value: 0,
            },
          ],
          value: 0,
        }
    },
  created() {
    console.log(2222, window.location.origin)
    this.getCheckinState()
    this.option[0].label = `${window.location.origin}?code=${this.$store.state.userInfo.inviteCode }`
  },
    mounted() {
        this.getActivityDetail()
        // this.check_in()
    },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    },
    methods: {
        check_in(){
            checkin().then(res=>{
                if (res.code==200){
                    this.flag = false;
                    this.getActivityDetail()
                }
            })
        },
        toUrl(url){this.$router.push(url)},
        handleCopy(e) {
          console.log(e)
        },
        async getCheckinState(){
            const { data: flag } = await getCheckinStatus()
            this.flag = flag;
        },
        getTextWidth(text, font) {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          context.font = font || '16px Arial'
          // 测量文本宽度
          const metrics = context.measureText(text)
          return metrics.width
        },
        tabChanged(e) {
          this.tabStyle = {
            '--lineWidth': this.getTextWidth(this.tabs[e], '16px') + 10 + 'px',
          }
        },
        onClickLeft() {
          this.$router.go(-1)
        },
        toNewPage() {
          window.open(this.option[0].label)
        },
        getActivityDetail() {
            activityDetail(
                this.$route.query.id
            ).then(res => {
                this.boxList = res.data
                if (this.boxList) {
                    let plus = this.boxList.itemList.length % 4;

                    this.totalLine = parseInt(this.boxList.itemList.length / 4);
                    if (plus > 0) {
                        this.totalLine = this.totalLine + 1;
                    }
                    console.log(this.totalLine)
                }
            })
        },
        openBox(item1){
            console.log(item1)
            if(item1.userActivityStatus=="-1" || !item1.userActivityStatus){
                this.$toast.fail("Não atende às condições e não pode ser ativado")
            }else if(item1.userActivityStatus=="0"){
                // alert(item1.userActivityId)
                receiveAccumulatedCheckinReward(item1.userActivityId).then(res=>{
                  if (res.code==200){
                    this.$toast.success("Recebido com sucesso")
                      this.getActivityDetail();
                  }else {
                    // this.$toast.fail(res.msg)
                    this.$toast.fail("Prezado usuário, você não atende aos requisitos para a obtenção. Por favor, complete a tarefa antes de resgatar.")
                  }
                })
            }else{
                this.$toast.fail("Você já está ativado")
            }
        },
        toBack() {
            this.$router.push('/home/event')
        }
    }
}
</script>

<style lang="scss" scoped>
.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #303030;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    background-color: rgba(0,0,0,0.84);
    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.d_flex-div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

.part_two {
    width: 390px;
    height: 305px;
    background: #000000;
    border-radius: 5px;

    .ptwo_div_1 {
        height: 30px;
        padding: 5px;
        border-bottom: 1px solid;
        margin: 0 10px;

        span {
            color: #fff;
        }
    }

    .ptwo_div_2 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        margin: 10px 0;

        .ptwo_div_2_1 {
            font-size: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 5px;
            background: #fff;
            height: 100px;
            margin-right: 3px;

            img {
                width: 70px;
                padding: 2px;
            }

            span {
                color: #000;
                background: #feb705;
                font-size: 10px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .ptwo_div_2_2 {
            display: flex;
            flex-direction: column;
            height: 90px;
            justify-content: space-between;

            .ptwo_div_2_2_1 {
                display: flex;
                justify-content: space-between;
                /* padding: 0 10px; */
            }

            .ptwo_div_2_2_2 {
                border: 1px solid #ccc;
                /* margin: 0 10px; */
                height: 60px;
                border-radius: 5px;

                span {
                    text-align: justify;
                    font-size: 12px;
                }
            }

        }
    }

    .ptwo_div_3 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .ptwo_div_3_1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 38px;
                height: 38px;
            }
        }
    }

}

.part_three {
    //width: 391px;
    /* height: 223px; */
    border-radius: 5px;
    background: #000;
    margin: 10px 0;
    text-align: center;

    p {
        font-size: 12px;
        padding: 5px;
    }

    .part_three_1 {
        background: #303030;
        margin: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 11px;
            width: 150px;
        }
    }
}

.part_four {
  padding: 0 10px;
    // margin: 0 10px;
    //padding-left: 15px;
    height: 100%;
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    .part_four_0 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .part_four_1 {
            display: flex;
            align-items: center;
            .part_four_1_1 {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center !important;
                width: 95px;
                height: 100%;
                img {
                    width: 65px !important;
                    height: 65px !important;
                }

                span {
                    color: #fff;
                }
            }

            .part_four_1_2 {
                margin: 0 10px;
            }
        }
    }

    .toLeft {
        width: 100%;
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        margin: 0 20px;
    }

    .toRight {
        width: 100%;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
    }

}

.part_five {
    //width: 391px;
    background: #000;
    border-radius: 5px;
    margin: 10px 0;
    padding-top: 10px;
    padding-left: 10px;

}

.popupCSS {
    width: 330px;
    height: 675px;
    background: #000;

    h2 {
        color: #fff;
        text-align: center;
    }

    .p1 {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;

        .p1_1 {
            width: 120px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .p1_2 {
            border-radius: 20px;
            border: 1px solid #ccc;
            width: 160px;
            display: flex;
            justify-content: center
        }
    }

    .nothing_css {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;
    }
}

.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
.qr-info-box1 {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.06rem;
  background: #000;

  .qr-title {
    color: #f1f1f1;
    font-size: 0.16rem;
    padding: 0.1rem;
    height: 0.52rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555;
  }

  .qr-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem;

    .qr-body-left {
      width: 1.2rem;

      .qr {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1.06rem;
        background: #f1f1f1;
        border-radius: 0.08rem;

        img {
          width: 0.9rem;
          margin: 0.1rem;
        }

        .qr-info {
          color: #1c1c1c;
          background: #f7b500;
          font-size: 0.14rem;
          border-bottom-left-radius: 0.08rem;
          border-bottom-right-radius: 0.08rem;
          text-align: center;
        }
      }
    }

    .qr-body-right {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .qr-body-right-top {
        display: flex;
        margin-top: 0.04rem;
        justify-content: space-between;
        align-items: center;

        span {
          color: #777777;
          font-size: 0.14rem;
        }

        .share {
          width: 0.2rem;
        }
      }

      .qr-body-right-bottom {
        margin-top: 0.1rem;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.qr-info-box {
  width: 92%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.06rem;
  background: #1c1c1c;

  .qr-title {
    color: #f1f1f1;
    font-size: 0.16rem;
    padding: 0.1rem;
    height: 0.52rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555;
  }

  .qr-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem;

    .qr-body-left {
      width: 1.2rem;

      .qr {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1.06rem;
        background: #f1f1f1;
        border-radius: 0.08rem;

        img {
          width: 0.9rem;
          margin: 0.1rem;
        }

        .qr-info {
          color: #1c1c1c;
          background: #f7b500;
          font-size: 0.14rem;
          border-bottom-left-radius: 0.08rem;
          border-bottom-right-radius: 0.08rem;
          text-align: center;
        }
      }
    }

    .qr-body-right {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .qr-body-right-top {
        display: flex;
        margin-top: 0.04rem;
        justify-content: space-between;
        align-items: center;

        span {
          color: #777777;
          font-size: 0.14rem;
        }

        .share {
          width: 0.2rem;
        }
      }

      .qr-body-right-bottom {
        margin-top: 0.1rem;
      }
    }
  }
}
.top-box {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0 0.2rem;
  background: #1c1c1c;
  border-radius: 0.1rem;
  margin: 0.2rem;
  position: relative;

  .top-box-left {
    width: 1rem;
    position: relative;
  }

  .img {
    position: absolute;
    width: 0.9rem;
    top: 0;
  }

  .top-box-center {
    width: 60%;

    .top-box-center-top {
      color: #767474;
      font-size: 0.15rem;
    }

    .top-box-center-bottom {
      color: #f7b500;
      font-size: 0.16rem;
      display: flex;
      align-items: flex-start;
      height: 0.2rem;

      span {
        margin-right: 0.1rem;
      }

      img {
        width: 0.19rem;
      }
    }

    .topp-box-Id {
      font-size: 0.16rem;
      margin-top: 0.14rem;

      span {
        font-size: 0.15rem;
      }

      span:nth-child(2) {
        padding-left: 0.08rem;
      }
    }
  }

  .top-box-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    button {
      background: #f7b500;
      color: #333;
      font-size: 0.12rem;
      border-radius: 0.06rem;
      padding: 0.05rem 0.1rem;
      margin-bottom: 0.1rem;
      outline: none;
      border: none;

      &:nth-child(2) {
        background: #999999;
        color: #f1f1f1;
      }
    }

    p {
      font-size: 0.19rem;
      color: #f7b500;
    }
  }
}

.softwares{
  display: flex;
  justify-content: space-around;
  .soft-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img{
      width: 40px;
    }
  }
}
</style>
